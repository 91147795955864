const projectData = [
    {
        "title": "CoffeEmergency",
        "description": "Helps users quickly locate the closest café or coffee shop.",
        "tools": [
            "Swift",
            "MapKit"
        ],
        "links": [
            {
                "title": "GitHub Repo",
                "url": "https://github.com/miles-au/CoffeEmergency"
            },
            {
                "title": "YouTube",
                "url": "https://www.youtube.com/watch?v=KoTOxgK__Ug&list=PLUQm-VQnY5s3NSJ9fbZ7EEKgkBDZSwEZO&index=2&t=0s"
            }
        ],
        "media": [
            "https://raw.githubusercontent.com/miles-au/CoffeEmergency/master/Screenshots/phone_home.PNG",
            "https://raw.githubusercontent.com/miles-au/CoffeEmergency/master/Screenshots/phone_map.PNG"
        ]
    },
    {
        "title": "Sellar",
        "description": "Listing multiple pieces of clothing to online marketplaces or e-commerce channels can be time consuming and tedious. Sellar aims to help get your clothing online faster.",
        "tools": [
            "Flutter",
            "Firebase",
            "ARKit",
            "ARCore",
            "RevenueCat"
        ],
        "links": [
            {
                "title": "App Store",
                "url": "https://apps.apple.com/us/app/sellar-listing-tool/id1505149522?ls=1"
            },
            {
                "title": "Play Store",
                "url": "https://play.google.com/store/apps/details?id=com.milesau.sellar"
            },
            {
                "title": "YouTube",
                "url": "https://www.youtube.com/watch?v=My69edS5ld0&list=PLUQm-VQnY5s3NSJ9fbZ7EEKgkBDZSwEZO&index=5&t=0s"
            }
        ],
        "media": [
            "https://is5-ssl.mzstatic.com/image/thumb/Purple123/v4/50/e1/25/50e125b0-8675-fe72-cf02-39e95d1cf275/pr_source.jpg/600x0w.jpg",
            "https://is3-ssl.mzstatic.com/image/thumb/Purple113/v4/90/59/f9/9059f9d1-a837-6b4d-3aa8-9bde68996d1c/pr_source.jpg/600x0w.jpg",
            "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/ad/ae/1a/adae1ac5-ced1-94fe-0a47-300526684b28/pr_source.jpg/600x0w.jpg",
            "https://is4-ssl.mzstatic.com/image/thumb/Purple123/v4/b8/79/ed/b879ed97-de36-3060-b46f-25339b5febcb/pr_source.jpg/600x0w.jpg",
        ]
    },
    {
        "title": "Live Price Converter",
        "description": "Convert currencies on price tags, receipts, menus and other physical prices to your preferred currency. Live Price Converter uses live text recognition to convert prices in real-time.",
        "tools": [
            "Swift",
            "Apple Text Recognition"
        ],
        "links": [
            {
                "title": "App Store",
                "url": "https://apps.apple.com/us/app/live-price-converter/id1494835186"
            },
            {
                "title": "YouTube",
                "url": "https://www.youtube.com/watch?v=bPC-2YLbVpQ&list=PLUQm-VQnY5s3NSJ9fbZ7EEKgkBDZSwEZO&index=4&t=0s"
            }
        ],
        "media": [
            "https://is5-ssl.mzstatic.com/image/thumb/Purple123/v4/a0/6d/96/a06d962a-3e86-4f4d-06c6-909ee0da085e/pr_source.jpg/600x0w.jpg",
            "https://is3-ssl.mzstatic.com/image/thumb/Purple123/v4/8b/47/44/8b474470-0648-3975-5212-4d452020298a/pr_source.jpg/600x0w.jpg"
        ]
    },
    {
        "title": "Digitails",
        "description": "Quickly share your social media and contact details digitally through a QR code. Digitails automatically generates a QR code that presents all your information, and another one that saves your information as a contact.",
        "tools": [
            "Flutter",
            "Ruby on Rails"
        ],
        "links": [
            {
                "title": "App Store",
                "url": "https://apps.apple.com/us/app/digitails/id1494513448"
            },
            {
                "title": "Play Store",
                "url": "https://play.google.com/store/apps/details?id=com.milesau.digitails"
            },
            {
                "title": "YouTube",
                "url": "https://www.youtube.com/watch?v=ODUB-nNFTBo&list=PLUQm-VQnY5s3NSJ9fbZ7EEKgkBDZSwEZO&index=7&t=0s"
            }
        ],
        "media": [
            "https://is4-ssl.mzstatic.com/image/thumb/Purple123/v4/3d/9f/02/3d9f0215-feb2-c1f0-f9da-2bafc027c37e/pr_source.jpg/600x0w.jpg",
            "https://is3-ssl.mzstatic.com/image/thumb/Purple113/v4/50/24/3d/50243da5-58a9-eaa2-0fa8-700fe4f40255/pr_source.jpg/600x0w.jpg",
            "https://is2-ssl.mzstatic.com/image/thumb/Purple123/v4/84/8e/e1/848ee170-9e94-2206-a133-6b2a59491439/pr_source.jpg/600x0w.jpg",
            "https://is3-ssl.mzstatic.com/image/thumb/Purple113/v4/bd/a0/d1/bda0d105-8a92-384e-cf2e-1a63e9a583cb/pr_source.jpg/600x0w.jpg"
        ]
    },
    {
        "title": "ARLines",
        "description": "A Swift package that is a shortcut to drawing various types of lines in ARKit",
        "tools": [
            "Swift",
            "ARKit"
        ],
        "links": [
            {
                "title": "GitHub Repo",
                "url": "https://github.com/miles-au/ARLines"
            }
        ],
        "media": [
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/ar_lines/IMG_6558.PNG?raw=true",
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/ar_lines/IMG_6559.PNG?raw=true",
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/ar_lines/IMG_6560.PNG?raw=true"
        ]
    },
    {
        "title": "Mitered Edge Tool",
        "description": "Augmented Reality assistant to draw the diagonal fold for a mitered edge.",
        "tools": [
            "Swift",
            "ARKit"
        ],
        "links": [
            {
                "title": "GitHub Repo",
                "url": "https://github.com/miles-au/StitchBuddy"
            },
            {
                "title": "YouTube",
                "url": "https://www.youtube.com/watch?v=dHZ8pH-B0tU&list=PLUQm-VQnY5s3NSJ9fbZ7EEKgkBDZSwEZO&index=2&t=0s"
            }
        ],
        "media": [
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/mitered_edge_tool/IMG_6553.PNG?raw=true",
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/mitered_edge_tool/IMG_6554.PNG?raw=true",
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/mitered_edge_tool/IMG_6555.PNG?raw=true"
        ]
    },
    {
        "title": "Roomspace",
        "description": "Roomspace is a mobile app that uses Augmented Reality to help realtors virtually stage their empty rooms.",
        "tools": [
            "Swift",
            "Firebase",
            "ARKit",
            "Blender"
        ],
        "links": [
            {
                "title": "YouTube",
                "url": "https://www.youtube.com/watch?v=VSyiTi5ovNA&list=PLUQm-VQnY5s3NSJ9fbZ7EEKgkBDZSwEZO&index=3&t=0s"
            }
        ],
        "media": [
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/roomspace/IMG_6561.PNG?raw=true",
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/roomspace/IMG_6562.PNG?raw=true"
        ]
    },
    {
        "title": "Show My Socials",
        "description": "Generate a social media printout that folds into a tabletop display.",
        "tools": [
            "React JS"
        ],
        "links": [
            {
                "title": "Web Page",
                "url": "https://milesau.com/show_my_socials/"
            }
        ],
        "media": [
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/show_my_socials/show_my_socials.png?raw=true",
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/show_my_socials/printout.png?raw=true",
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/show_my_socials/tabletop.jpg?raw=true"
        ]
    },
    {
        "title": "Promo Posts",
        "description": "Unsplash + Hootsuite for the promotional products industry",
        "tools": [
            "Ruby on Rails",
            "JQuery",
            "Heroku",
            "Bootstrap",
            "Google Cloud Console"
        ],
        "links": [
            {
                "title": "GitHub Repo",
                "url": "https://github.com/miles-au/promoposts"
            }
        ],
        "media": [
            "https://github.com/miles-au/miles-au.github.io/blob/develop/public/images/promo_posts/promo_posts.png?raw=true"
        ]
    }
]

export default projectData